<template>
  <header>
    <nav-bar :is_open="is_menu_open"/>
  </header>
  <main>
    <router-view/>
  </main>
  <footer>
    <footer-bar />
  </footer>
</template>

<script>

import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";

export default {
  name: "App",
  components: {NavBar, FooterBar},
  data() {
    return {
      is_menu_open: false,
    }
  },
  methods:{
    toggleMenu(){
        this.is_menu_open = !this.is_menu_open;
    }
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | My Awesome Webapp'
  }
};
</script>
