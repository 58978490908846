<template>
	<div class="contact1">
		<div class="container-contact1" v-on:click.stop>
      <slot name="close"></slot>
      <Tilt class="contact1-pic">
        <img :src="logo_5" alt="" width="290">
      </Tilt>
			<form class="contact1-form validate-form" v-on:submit.prevent>
				<span class="contact1-form-title">
          <h3>Напишите нам</h3>
				</span>

				<div
            class="wrap-input1 validate-input"
            :data-validate="feedback.name.error"
            :class="{ 'alert-validate': this.feedback.name.error !== '' }"
        >
					<input class="input1" type="text" name="name" placeholder="Имя" v-model="feedback.name.value"
                      @focus="resetError('name')"
>
					<span class="shadow-input1"></span>
				</div>

				<div
            class="wrap-input1 validate-input"
            :data-validate="feedback.email.error"
            :class="{ 'alert-validate': feedback.email.error !== '' }"
        >
					<input class="input1" type="text" name="email" placeholder="Email" v-model="feedback.email.value"
                      @focus="resetError('email')"
>
					<span class="shadow-input1"></span>
				</div>

				<div
            class="wrap-input1 validate-input"
            :data-validate="feedback.phone.error"
            :class="{ 'alert-validate': feedback.phone.error !== '' }"
        >
					<input class="input1" type="text" name="phone" placeholder="Номер телефона" v-model="feedback.phone.value"
                      @focus="resetError('phone')"
          >
					<span class="shadow-input1"></span>
				</div>

				<div
            class="wrap-input1 validate-input"
            :data-validate="feedback.message.error"
            :class="{ 'alert-validate': feedback.message.error !== '' }"
        >
					<textarea
              class="input1"
              name="message"
              placeholder="Сообщение"
              v-model="feedback.message.value"
              @focus="resetError('message')"
          ></textarea>
					<span class="shadow-input1"></span>
				</div>

				<div class="container-contact1-form-btn">
					<button class="dark-green" @click="sendForm">Отправить</button>
				</div>
			</form>
		</div>
	</div>

</template>

<script>
import Tilt from 'vanilla-tilt-vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

const FeedbackRepository = RepositoryFactory.get("feedback");

export default {
  name: "ContactForm",
  components: {Tilt},
  data() {
    return {
      logo_5: require("@/assets/logo_square.jpg"),
      feedback: {
        name: {
          value: '',
          error: ''
        },
        email: {
          value: '',
          error: ''
        },
        phone: {
          value: '',
          error: ''
        },
        message: {
          value: '',
          error: ''
        }
      }
    }
  },
  methods:{
    sendForm(){
      this.resetErrors();
      const name =this.feedback.name.value;
      const email =this.feedback.email.value;
      const phone =this.feedback.phone.value;
      const message =this.feedback.message.value;
      FeedbackRepository.create(name, email, phone, message).then(
          () => {
            this.resetForm();
            this.$emit('close');
          }
      ).catch(
          (error) => {
            if (error.response.status === 400) {
              this.processErrors(error.response.data)
            }
          }
      )
    },
    resetErrors() {
      const _this = this;
      Object.keys(this.feedback).forEach(function (key) {
          _this.feedback[key].error = '';
      });
    },
    resetError(key) {
      this.feedback[key].error = '';
    },
    resetForm() {
      const _this = this;
      Object.keys(this.feedback).forEach(function (key) {
          _this.feedback[key].error = '';
          _this.feedback[key].message = '';
      });
    },
    processErrors(data) {
      const _this = this;
      Object.keys(data).forEach(function (key) {
        let msg = data[key][0];
        if (msg[msg.length-1] === ".")
          msg = msg.slice(0,-1);
        if (key === 'non_field_errors') {
          _this.feedback.email.error = msg
        } else {
          _this.feedback[key].error = msg
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.contact1 {
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container-contact1 {
  background: $white;
  border-radius: 10px;
  position: relative;
  max-width: 1162px;
  width: 90%;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 90px 130px 88px 148px;
}

.contact1-pic img {
  max-width: 100%;
}

.contact1-form {
  width: 390px;
}

.contact1-form-title {
  display: block;
  color: $dark-green;
  text-align: center;
  padding-bottom: 44px;
}

input.input1 {
  height: 50px;
  border-radius: 25px;
  padding: 0 30px;
}
input.input1 + .shadow-input1 {
  border-radius: 25px;
}

textarea.input1 {
  min-height: 150px;
  border-radius: 25px;
  padding: 12px 30px;
}
textarea.input1 + .shadow-input1 {
  border-radius: 25px;
}

textarea {
  resize: none;
  border: none;
}

/*---------------------------------------------*/
.wrap-input1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.input1 {
  display: block;
  width: 100%;
  background: $gray;
  color: $dark-green;
}

.shadow-input1 {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(85,115,78, 0.5);
}

.input1:focus + .shadow-input1 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

/*---------------------------------------------*/
.container-contact1-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact1-form-btn i {
  margin-left: 7px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact1-form-btn:hover {
  background: #333333;
}

.contact1-form-btn:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}
/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 1200px) {
  .contact1-pic {
    width: 33.5%;
  }

  .contact1-form {
    width: 44%;
  }
}

@media (max-width: 992px) {
  .container-contact1 {
    padding: 90px 80px 88px 90px;
  }

  .contact1-pic {
    width: 35%;
  }

  .contact1-form {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .container-contact1 {
    padding: 90px 80px 88px 80px;
  }

  .contact1-pic {
    display: none;
  }

  .contact1-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container-contact1 {
    padding: 90px 15px 88px 15px;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: $white;
  border: 1px solid $dark-green;
  border-radius: 13px;
  padding: 4px 10px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  color: $dark-green;
  text-align: left;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
</style>