<template>
  <a :href="link" target="_blank"><font-awesome-icon :icon="faCode" /></a>
</template>

<script>
export default {
  name: "FaLink",
  props: ['link', 'code'],
  computed: {
    faCode: function () {
      const data = {
        'telegram': 'fa-telegram',
        'vk': 'fa-vk',
        'instagram': 'fa-instagram'
      }
      return "fa-brands " + data[this.code];
    }
  }
}
</script>

<style scoped>

</style>