<template>
  <div class="dog">
    <img :src="logo_5" alt="">
  </div>
  <div class="olive-block p-2 center">
    <div class="text">Мы говорим о собаках-помощниках</div>
    <div class="text">для людей с ментальными проблемами и не только</div>
  </div>
  <div class="home_dog center">
    <img alt="" :src="pages.home.img.src">
  </div>
  <div class="olive-block about flex-list center">
    <div class="wrapper p-2">
      <h1>О нас</h1>
      <br>
      <div class="text">Мы - инициативная группа с самой грандиозной целью: сделать мир лучше!</div>
      <div class="text">Во многих странах существуют специально обученные собаки для людей с ментальными заболеваниями,
        благодаря которым такие люди имеют возможность получать экстренную помощь и постоянную реабилитацию.
      </div>
      <br>
      <br>
      <div class="text">
        В России это направление только начинает развиваться, но мы прилагаем все усилия для&nbsp;того, чтобы узаконить
        таких собак и сделать их доступными, облегчив жизнь огромной части населения.
      </div>
      <br>
      <animated-arrow-button @click="$router.push('/about')">Подробнее</animated-arrow-button>
    </div>
  </div>
</template>

<script>
import AnimatedArrowButton from "@/components/AnimatedArrowButton";
import {mapState} from "vuex";

export default {
  name: "HomePage",
  components: {AnimatedArrowButton},
  mounted(){
    document.title = 'Дело в собаке | Главная';
  },
  computed: {
    ...mapState({
      pages: (state) => state.page.pages
    }),
  },
  data: function () {
    return {
      logo_5: require("@/assets/logo_green_5.png"),
    };
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.dog {
  text-align: center;
}

.dog img {
  max-width: 400px;
  width: 100%;
  @media screen and (max-width: $phone-width) {
    display: none;
  }
}

.home_dog {
  padding-top: 60px;
  padding-bottom: 60px;
}

.wrapper {
  max-width: 960px;
}

.contacts{
  h2 {
    padding-bottom: 50px;
  }
  .wrapper {
    width: 100%;
  }
  div {
    font-size: 24px;
  }
  .wrapped {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 680px) {
      flex-direction: column;
    }
  }
  .links {
    text-align: left;
    div {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>