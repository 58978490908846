<template>
  <div class="gray-block contacts center flex-column p-2">
    <div class="wrapper p-2">
      <h2>Наши контакты</h2>
      <br>
      <div class="wrapped">
        <div class="links text-l">
          <div>Почта: <a class="main" href="mailto:delovdogs@gmail.com">delovdogs@gmail.com</a></div>
        </div>
        <button class="gray-button">
          <a href="https://planeta.ru/campaigns/delovdogs2023" target="_blank">
            Поддержать проект
          </a>
        </button>
        <button class="dark-green" @click="openModal">Связаться с нами</button>
      </div>
      <div class="text-left">
        <fa-link
            v-for="shortcut in shortcuts"
            v-bind:key="shortcut.id"
            :link="shortcut.link"
            :code="shortcut.code"
        >
        </fa-link>
      </div>
    </div>
  </div>
  <modal-block :is_open="is_open" @close="closeModal">
    <contact-form @close="closeModal">
      <template v-slot:close>
        <div class="modal-close" @click="closeModal">✕</div>
      </template>
    </contact-form>
  </modal-block>
</template>

<script>
import ModalBlock from "@/components/ModalBlock";
import ContactForm from "@/components/ContactForm";
import {mapActions, mapState} from "vuex";
import FaLink from "@/components/FaLink";
export default {
  name: "FooterBar",
  components: {FaLink, ContactForm, ModalBlock},
  data() {
    return {
      is_open: false
    }
  },
  computed: {
    ...mapState({
      shortcuts: (state) => state.shortcut.shortcuts
    }),
  },
  created() {
    this.fetchShortcuts();
    this.fetchMembers();
    this.fetchArticles();
  },
  methods: {
    ...mapActions({
      fetchShortcuts: "shortcut/fetchShortcuts",
      fetchMembers: "member/fetchMembers",
      fetchArticles: "article/fetchArticles",
    }),
    closeModal() {
      this.is_open = false;
    },
    openModal() {
      this.is_open = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.gray-button {
  background-color: #1a82ff;
  a {
    color: $white;
  }
    &:hover {
      a {
        color: #1a82ff;
      }
      background-color: $white;
      box-shadow: #1a82ff40 0px 0px 20px 0px;
    }
}
.wrapper {
  max-width: 960px;
}

.contacts{
  .wrapper {
    width: 100%;
  }
  .wrapped {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 680px) {
      flex-direction: column;
      button {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .links {
    text-align: left;
  }
  .text-left {
    a {
      margin-right: 10px;
    }
    @media screen and (max-width: 680px) {
      text-align: center;
    }
  }
}
.field {
  width: 100%;
  color: $olive;
  label {
    display: block;
  }
  input, textarea {
    color: $dark-green;
    border: 1px solid #89AA82;
    border-radius: 3px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px;
    width: 100%;
    max-width: 100%;
  }
  textarea {
    height: 85px;
  }
}
.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  @media screen and (max-width: $phone-width) {
    justify-content: center;
  }
}

.modal-close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #55734E;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  box-shadow: rgb(255 255 255 / 70%) 0px 0px 5px 0px;
  font-size: 10px;
  line-height: 21px;
}

</style>
