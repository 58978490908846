<template>
  <main-block :src="pages.home.img.src">О НАС</main-block>
  <div class="white-block center flex-column p-1">
    <div class="text w-800">Мы создали проект “Дело в собаке”,
      посвящённый собакам-помощникам для людей с ментальными, неврологическими,
      эндокринными и некоторыми другими заболеваниями.
    </div>
    <br>
    <button class="dark-green" @click="$router.push('/helpers')">Что это за собаки?</button>
    <br>
    <div class="text-2 p-1 w-800">
      Наша команда состоит из владельцев собак, профессиональных кинологов, переводчиков, просто энтузиастов,
      а также потенциальных благополучателей. Мы не понаслышке знаем, как собака может помогать в смягчении и устранении различных симптомов.
      Уже сейчас мы столкнулись с большим количеством людей, которые нуждаются в собаках психиатрической помощи, собаках для людей с эпилепсией или диабетом.
    </div>
  </div>
  <div class="white-block flex-column">
    <h2>Наша команда</h2>
    <div class="team p-2">
      <div v-for="member in members" class="member" v-bind:key="member.id">
        <div class="member-img" v-bind:style="{ backgroundImage: 'url(' + host + member.img + ')' }" >
          <meta itemprop="image" :content="member.img">
        </div>
        <h4> {{ member.name }}</h4>
        <div class="text-small"> {{ member.post }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import MainBlock from "@/components/MainBlock";
import {mapState} from "vuex";
import {BACKEND_HOST} from "@/constants";

export default {
  name: "AboutPage",
  components: {MainBlock},
  computed: {
    ...mapState({
      members: (state) => state.member.members,
      pages: (state) => state.page.pages,
    }),
  },
  mounted() {
    document.title = 'Дело в собаке | О нас';
  },
  data() {
    return {
      host: BACKEND_HOST,
    };
  },
}
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  max-width: 1200px;
}

.member {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 40px;
  width: 260px;
  @media screen and (max-width: 640px) {
    width: 50%;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
  }
}

.member-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 100%;
}

.text-small {
  padding-top: 10px;
}
h4 {
  padding-top: 20px;
}

</style>