import HomePage from "@/pages/HomePage";
import {createRouter, createWebHistory} from "vue-router";
import AboutPage from "@/pages/AboutPage";
import ArticlesPage from "@/pages/ArticlesPage";
import HelpersPage from "@/pages/HelpersPage";
import GoalsPage from "@/pages/GoalsPage";

const routes = [
  {
    path: "/",
    component: HomePage,
    name: 'Главная',
  },
  {
    path: "/about",
    component: AboutPage,
    name: 'О нас',
  },
  {
    path: "/helpers",
    component: HelpersPage,
    name: 'Собаки-помощники',
  },
  {
    path: "/future",
    component: GoalsPage,
    name: 'Планы и цели',
  },
  {
    path: "/articles",
    component: ArticlesPage,
    name: 'Статьи',
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router;
